<template>
    <user-banner />
</template>

<script>
import UserBanner from '@/components/Home/UserBanner.vue';

export default {
    components: { UserBanner },
}
</script>

<style lang="scss" scoped>

</style>