<template>
    <div class="banner has-background-primary">
        <!-- Main Content -->
        <div class="inner-header pt-4 columns is-vcentered">
            <div class="column is-one-third">
                <upload-image v-model="form.img" @on-cropp="onCropp">
                    <div class="avatar-container">
                        <img
                            v-if="form.img"
                            :src="form.img"
                            :alt="perfil.trabajador.usuario.username || ''"
                        />
                        <no-user-image
                            v-else
                            size="large"
                            :trabajador="perfil.trabajador"
                        />
                        <a href="#">
                            <b-icon icon="pencil-outline" />
                        </a>
                    </div>
                </upload-image>
            </div>
            <div class="column is-two-thirds has-text-left-desktop">
                <h2
                    class="is-size-5-mobile is-size-4-tablet is-size-4-desktop"
                    style="font-weight: bold"
                >
                    {{
                        `${perfil.trabajador.nombre || ''} ${
                            perfil.trabajador.apellido_paterno || ''
                        } ${perfil.trabajador.apellido_materno || ''}`
                    }}<br />
                    ({{ perfil.trabajador.usuario.persona.dni }})
                </h2>
                <a
                    class="banner--link"
                    href=""
                    @click.prevent="setIsModalCambiarEmail(true)"
                >
                    <div v-if="perfil.trabajador.usuario.email">
                        <h4
                            class="is-size-6-mobile is-size-5-tablet is-size-5-desktop"
                        >
                            {{ perfil.trabajador.usuario.email }}
                            <b-icon
                                class="ml-1"
                                icon="pencil-outline"
                                size="is-small"
                            />
                        </h4>
                    </div>
                    <div v-else>
                        <h5 class="is-italic">Ingrese una dirección email</h5>
                    </div>
                </a>
                <br />
                <div class="buttons user-banner-buttons">
                    <b-button
                        type="is-success is-light"
                        icon-left="file-document-outline"
                        tag="router-link"
                        to="/u/documentos"
                        rounded
                    >
                        Ver documentos
                    </b-button>
                    <b-button
                        type="is-light"
                        icon-left="information"
                        @click="toggleShowInfo()"
                        outlined
                        rounded
                    >
                        Mas información
                    </b-button>
                </div>
            </div>
        </div>

        <!-- Extra Info -->
        <transition name="slide">
            <div class="columns mt-1 is-mobile is-centered" v-if="showInfo">
                <div class="column is-two-thirds">
                    <b-notification
                        :closable="false"
                        class="is-relative"
                        style="background-color: rgb(255, 255, 255, 0.5);"
                    >
                        <b-loading :is-full-page="false" v-model="isLoading.info" />

                        <div class="level">
                            <div class="level-item has-text-centered">
                                <div>
                                    <p class="heading">DNI</p>
                                    <p class="subtitle">{{ info.personal.dni }}</p>
                                </div>
                            </div>
                            <div class="level-item has-text-centered">
                                <div>
                                    <p class="heading">País</p>
                                    <div class="is-flex is-align-content-center">
                                        <img :src="info.personal.pais_ruta_imagen" class="image is-24x24" />
                                        <p class="ml-1 subtitle">
                                            {{ info.personal.pais }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="level-item has-text-centered">
                                <div>
                                    <p class="heading">Edad</p>
                                    <p class="subtitle">{{ info.personal.edad }}</p>
                                </div>
                            </div>
                            <div class="level-item has-text-centered">
                                <div>
                                    <p class="heading">Dirección</p>
                                    <p class="subtitle">-</p>
                                </div>
                            </div>
                        </div>
                        <div class="level">
                            <div
                                v-for="data of info.laboral" :key="data.campo_dato_laboral_id"
                                class="level-item has-text-centered"
                            >
                                <div>
                                    <p class="heading">{{ data.campo.descripcion }}</p>
                                    <p class="subtitle">{{ data.valor }}</p>
                                </div>
                            </div>
                        </div>
                    </b-notification>
                </div>
            </div>
        </transition>

        <!-- Stats carousel -->
        <div class="slider p-5 mt-1 columns">
            <div class="column">
                <div class="card banner--card">
                    <div class="card-content">
                        <div class="columns is-mobile is-vcentered">
                            <div class="column is-one-third-mobile">
                                <img
                                    :src="
                                        require('@/assets/svg/001-folder.svg')
                                    "
                                    style="width: 80%"
                                />
                            </div>
                            <div class="column is-two-thirds-mobile">
                                <p class="has-text-weight-bold is-uppercase">
                                    <span
                                        class="has-text-primary is-size-4 mr-1"
                                        >{{ stats.documentos_a_firmar }}</span
                                    >
                                    documentos a firmar
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="card banner--card">
                    <div class="card-content">
                        <div class="columns is-mobile is-vcentered">
                            <div class="column is-one-third-mobile">
                                <img
                                    :src="
                                        require('@/assets/svg/004-note.svg')
                                    "
                                    style="width: 80%"
                                />
                            </div>
                            <div class="column is-two-thirds-mobile">
                                <p class="has-text-weight-bold is-uppercase">
                                    <span
                                        class="has-text-primary is-size-4 mr-1"
                                        >{{ stats.documentos_firmados }}</span
                                    >
                                    documentos firmados
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="card banner--card">
                    <div class="card-content">
                        <div class="columns is-mobile is-vcentered">
                            <div class="column is-one-third-mobile">
                                <img
                                    :src="
                                        require('@/assets/svg/002-file.svg')
                                    "
                                    style="width: 80%"
                                />
                            </div>
                            <div class="column is-two-thirds-mobile">
                                <p class="has-text-weight-bold is-uppercase">
                                    <span
                                        class="has-text-primary is-size-4 mr-1"
                                        >{{ stats.documentos_publicados }}</span
                                    >
                                    documentos publicados
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Decoracion de olas -->
        <waves />
    </div>
</template>

<script>
import { tns } from 'tiny-slider/src/tiny-slider';
import NoUserImage from '@/components/shared/NoUserImage.vue';
import Waves from '@/components/shared/Waves.vue';
import UploadImage from '@/components/shared/UploadImage.vue';
import Message from '@/utils/Message';

export default {
    components: { NoUserImage, Waves, UploadImage },
    name: 'UserBanner',
    data() {
        return {
            isLoading: {
                info: false,
                stats: false,
            },
            showInfo: false,
            form: {
                img: ''
            }
        };
    },
    computed: {
        perfil() {
            return this.$store.state.me.perfil;
        },
        info() {
            return this.$store.state.me.info;
        },
        stats() {
            return this.$store.state.me.stats;
        },
        fotoPerfil() {
            return this.$store.state.me.perfil.trabajador.usuario.ruta_imagen
                ? `${this.$store.state.me.perfil.trabajador.usuario.ruta_imagen}`
                : null;
        },
        img() {
            return "data:image/jpg;base64," + this.$store.state.me.img;
        }
    },
    mounted() {
        let slider = tns({
            container: '.slider',
            items: 1,
            gutter: 20,
            swipeAngle: false,
            autoplay: true,
            mouseDrag: true,
            controls: false,
            nav: false,
            autoplayButton: false,
            nextButton: false,
            autoplayButtonOutput: false,
            responsive: {
                640: {
                    items: 2,
                },
                900: {
                    items: 3,
                },
            },
        });

        this.fetchStats();
        this.fetchUserImg();
    },
    methods: {
        async fetchInfo() {
            this.isLoading.info = true;
            try {
                const res = await this.$store.dispatch('me/getInfo');

            } catch (err) {
                Message.showErrorMessage(this, err.message);
            } finally {
                this.isLoading.info = false;
            }
        },
        async fetchStats() {
            this.isLoading.stats = true;
            try {
                const res = await this.$store.dispatch('me/getStats');

            } catch (err) {
                Message.showErrorMessage(this, err.message)
            } finally {
                this.isLoading.stats = false;
            }
        },
        async fetchUserImg() {
            await this.$store.dispatch('me/getImage');
        },
        async updateUserImg() {
            try {
                const res = await this.$store.dispatch('me/uploadImage', this.form);
                /* this.$buefy.notification.open({
                    type: 'is-success',
                    message: res.message
                }); */
                Message.showSuccessMessage(this, res.message);
                // await this.$store.dispatch('me/get');
            } catch (err) {
                /* this.$buefy.notification.open({
                    type: 'is-danger',
                    message: err.message,
                }); */
                Message.showErrorMessage(this, err.message);
            }
        },
        async onCropp() {
            this.$buefy.dialog.confirm({
                message: '¿Desea guardar esta foto?',
                closeOnConfirm: false,
                onConfirm: async (value, {close}) => {
                    this.$buefy.toast.open(`Guardando...`)
                    await this.updateUserImg();
                    close();
                }
            })
        },
        setIsModalCambiarEmail(value) {
            this.$store.dispatch('ui/setIsModalCambiarEmail', value);
        },
        setIsModalSubirImagen(value) {
            this.$store.dispatch('ui/setIsModalSubirImagen', value);
        },
        toggleShowInfo() {
            this.showInfo = !this.showInfo;
        },
    },
    watch: {
        showInfo() {
            if (this.showInfo) {
                this.fetchInfo();
            }
        },
        img: function(value) {
            this.form.img = value;
        }
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.banner {
    position: relative;
    text-align: center;
    color: white;
}

.banner--link {
    color: white;

    &:hover {
        text-decoration: underline;
    }
}

.inner-header {
    min-height: 35vh;
    width: 100%;
    margin: 0;
    padding: 0;
}

.content {
    position: relative;
    height: 20vh;
    text-align: center;
    background-color: white;
}

.banner--card {
    max-width: 80%;
    margin: auto;
    background: rgba(255, 255, 255, 0.3);
    transition: 0.2s linear 0.1s;

    span,
    p {
        color: white !important;
    }
}

.banner--card:hover {
    background: white;

    span,
    p {
        color: $primary !important;
    }
}

@media (max-width: 768px) {
    .user-banner-buttons {
        justify-content: center !important;
    }
}
</style>
